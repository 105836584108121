(function() {
    'use strict';

    angular.module('adminApp')
        .run(runBlock);

    runBlock.$inject = ['$rootScope', 'authenticate', 'saasConstants', 'sessionService', 'tabStatesService'];
    function runBlock($rootScope, authenticate, saasConstants, sessionService, tabStatesService) {
        // Load constants
        saasConstants.setConstants();
        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            authenticate.createIntended(toState, toParams);
            if (toState.name !== 'otherwise') {
                var check = tabStatesService.checkNeeded(toState, fromState, toParams, fromParams);
                if (check) {
                    sessionService.ensure(event, toState, toParams);
                }
            } else {
                $rootScope.$broadcast('stateChangeOccurred', {name: toState.name});
            }
        });
    }
})();
