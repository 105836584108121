(function() {
    'use strict';

    angular.module('adminApp')
        .config(httpProvider);

    httpProvider.$inject = ['$httpProvider'];
    function httpProvider($httpProvider) {
        $httpProvider.interceptors.push('httpRequestInterceptor');
    }
})();
