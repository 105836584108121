(function() {
    angular.module('adminApp', [
        'ui.router', 'ngFlash', 'angular-loading-bar', 'ngNotificationsBar', 'ngSanitize', 'ui-notification',
        'angularMoment', 'cp.ngConfirm', '720kb.tooltips', 'oc.lazyLoad', 'googlechart', 'ngclipboard',
        'ui.select', 'markdown', 'ngMaterial', 'rzModule',
        'authApp', 'adminApp.filters',
        'adminApp.auth', 'adminApp.home', 'adminApp.users', 'adminApp.saas', 'adminApp.licenses',
        'adminApp.coreapi', 'adminApp.wurfl', 'adminApp.cloud', 'adminApp.wjs', 'adminApp.wit',
        'adminApp.cut', 'adminApp.apikey', 'adminApp.external', 'adminApp.reports', 'adminApp.quwurfl'
    ])
    .config(function(NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 5000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'center',
            positionY: 'top',
            maxCount: 0
        });
    });
})();
